import React, { useState, useCallback, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label } from '../../common/Label';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileUploadDialog from '../FileUploadDialog';
import { get, clone, isEmpty } from '../../../utils/lodash';
import {
  Grid,
  FormControl,
  Box,
  Typography,
  TextField,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  ClickAwayListener,
  IconButton,
  Tooltip,
  Avatar,
  withStyles,
} from '@material-ui/core';
import PanelTemplate from '../../common/PanelTemplate';
import { connect } from 'react-redux';
import { handlePopupBlocker, isPermitSetAvailable } from '../../../utils/helper';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import Icons, { atsPhoto } from '../images';
import { getS3URL } from '../../../containers/Permitting/action';
import { fileUtils } from '../../../containers/Permitting/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2.5),
  },
  panelContainer: {
    margin: 0,
    padding: 0,
    border: 'none',
  },
  tooltipStyle: {
    maxWidth: 600,
  },
  electricalContainer: {
    padding: theme.spacing(2.5),
    display: 'flex',
    flexDirection: 'column',
  },
  itemsHolder: {
    position: 'relative',
    '&>div': {
      margin: '24px 8px 0px 8px',
      '&:first-child': {
        marginTop: 0,
      },
    },
  },

  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  labelColor: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 400,
  },

  inputItemWrapper: {
    maxWidth: '49%',
    padding: '12px 0 0px 0px !important',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      width: '100%',
      paddingRight: '28px !important',
    },
  },
  gridContainer: {
    justifyContent: 'space-between',
  },
  displayFlex: {
    display: 'flex',
  },
  orSpanStyle: {
    padding: theme.spacing(3, 3, 0, 3),
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2, 0, 1),
    },
  },
  addNew: {
    fontSize: 14,
    color: '#008EEF',
    display: 'inline-block',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },
  imagePhotoBorder: {
    marginTop: theme.spacing(1),
  },
  imgBoxDescription: {
    textAlign: 'center',
  },
  imgBoxBorder: {
    borderColor: 'lightgrey',
    border: '1px solid',
  },
  imageFileName: {
    marginTop: theme.spacing(0.5),
  },
  close: {
    position: 'absolute',
    left: theme.spacing(12.5),
    top: theme.spacing(0),
  },
  photosArray: {
    paddingRight: theme.spacing(1),
  },
  array: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    background: '#F7F7F7 0% 0% no-repeat padding-box',
  },
  arrayBox: {
    marginRight: theme.spacing(4),
  },
  paddingTop2: {
    paddingTop: theme.spacing(2),
  },
  paddingTop3: {
    paddingTop: theme.spacing(2.5),
  },
  error: {
    color: 'red',
  },
  arrayList: {
    padding: theme.spacing(2, 2, 2, 2),
    background: '#F7F7F7 0% 0% no-repeat padding-box',
  },
  imgContainer: {
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
  },
  tooltipContainer: {
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(60),
      overflowY: 'scroll',
    },
  },
  tooltipImage: {
    height: theme.spacing(25),
    width: theme.spacing(25),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(20),
      width: theme.spacing(22),
    },
  },
  imgIcon: {
    padding: theme.spacing(4),
  },
  tooltipLargeImage: {
    height: theme.spacing(25),
    width: theme.spacing(60),
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(16),
      width: theme.spacing(38),
    },
  },
  infoIcon: {
    marginTop: theme.spacing(2),
    padding: 0,
    marginRight: theme.spacing(1.5),
    color: 'grey',
  },
  uploadPhotoInfoIcon: {
    display: 'flex',
  },
  UploadPhotoSection: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  photoHeading: {
    display: 'flex',
    fontSize: 14,
  },
  generatorPhotos: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  generatorPhotoSection: {
    marginLeft: theme.spacing(1),
  },
  inputFieldSize: {
    width: '90%',
    minWidth: theme.spacing(20),
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(3),
      minWidth: 'auto',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  inputArrayField: {
    minWidth: theme.spacing(20),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
}));
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: '#111111',
    borderRadius: 0,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: theme.spacing(1),
    pointerEvents: 'auto',
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      minWidth: theme.spacing(40),
    },
  },
  arrow: {
    color: 'white',
    fontSize: 14,
  },
}))(Tooltip);

const GeneratorTooltip = (props) => {
  const { classes, isMobile } = props;
  let dividerVariant = 'vertical',
    flexItem = true;
  if (isMobile) {
    dividerVariant = 'horizontal';
    flexItem = false;
  }

  return (
    <Box className={classes.tooltipContainer}>
      <Box className={classes.imageRow}>
        <Box className={classes.imageContainer}>
          <Typography className={classes.tooltipText}>ATS</Typography>
          <Avatar variant="square" alt="enphase logo" src={atsPhoto} className={classes.tooltipImage} />
        </Box>
      </Box>
    </Box>
  );
};

const AddPhotoSection = (props) => {
  const { classes, photos, photosEmpty, keyValue = '', deletePhotos, handleFileDialogOpen, isMobile } = props;

  const canUpload = photos.length < 1;
  const [isTooltipOpen, setIstooltipOpen] = useState(false);
  const tooltipPlacement = isMobile ? 'top' : 'right-start';

  const fileIcon = (fName) => {
    let fType = 'document';
    if (fName && fName.includes('.')) {
      let fTypeArray = fName.split('.');
      fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
      if (fType === 'jpg') fType = 'jpeg';
      if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
        fType = 'document';
      }
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  const downloadPhoto = (photo) => {
    getS3URL({
      fileName: photo.file_name,
      methodType: 'GET',
      successCb: (preSignedS3Url) => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      },
    });
  };

  return (
    <Box className={classes.uploadPhotoInfoIcon}>
      <Box className={classes.UploadPhotoSection}>
        <Typography className={classes.photoHeading}>Automatic Transfer Switch (ATS)</Typography>
        {canUpload && (
          <Typography component="div" onClick={() => handleFileDialogOpen(keyValue)} className={classes.addNew}>
            + Add Image
          </Typography>
        )}
        <Box className={clsx(classes.displayFlex, classes.imagePhotoBorder)}>
          {photos.map((photo, i) => {
            return (
              <Box key={`${keyValue}-${i}`}>
                {photo.loading && <CircularProgress size={25} />}
                {!photo.loading && (
                  <Box className={classes.imgBoxDescription}>
                    <Box className={classes.imgBoxBorder}>
                      <Box className={classes.displayFlex}>
                        <Box className={classes.imgIcon}>
                          <IconButton onClick={() => downloadPhoto(photo)}>{fileIcon(photo.file_name)}</IconButton>
                        </Box>
                        <CloseIcon onClick={() => deletePhotos(photo.ind)} className={classes.imageCloseIcon} />
                      </Box>
                    </Box>
                    <Typography variant="caption" display="block" className={classes.imageFileName}>
                      {photo.original_file_name.substr(0, 15)}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
      {/* <ClickAwayListener onClickAway={e => setIstooltipOpen(false)}>
        <LightTooltip
          open={isTooltipOpen}
          title={<GeneratorTooltip isMobile={isMobile} classes={classes} />}
          placement={tooltipPlacement}
          arrow
        >
          <InfoIcon
            className={classes.infoIcon}
            onClick={e => setIstooltipOpen(true)}
            onMouseEnter={e => setIstooltipOpen(true)}
            onMouseLeave={e => setIstooltipOpen(false)}
            aria-label="info"
          >
            {' '}
          </InfoIcon>
        </LightTooltip>
      </ClickAwayListener> */}
      <ClickAwayListener onClickAway={(e) => setIstooltipOpen(false)}>
        <div>
          <Tooltip
            classes={{ tooltip: classes.tooltipStyle }}
            placement="bottom-start"
            onClose={(e) => setIstooltipOpen(false)}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Fragment>
                <img width={600} height={400} src={atsPhoto} />
              </Fragment>
            }
          >
            <IconButton className={classes.infoIcon}>
              <InfoIcon onClick={(e) => setIstooltipOpen(true)} />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </Box>
  );
};
function Generator(props) {
  const { materialDetails, handleChange, applicationDetail, mostUsedValues, isMobile } = props;
  const permitFilesAvailable = isPermitSetAvailable(applicationDetail);
  const { electrical_properties, errors = {} } = materialDetails;
  const {
    existing_generator,
    generator_connection,
    generator_manufacturer,
    generator_model,
    generator_quantity = 1,
  } = electrical_properties;
  const classes = useStyles();

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [imageSectionKey, setImageSectionKey] = useState('');

  const generatorTypeTag = 'ATS';

  let generatorTypePhotos = [];

  for (let index in materialDetails.electrical_properties.generator_photos) {
    let photo = materialDetails.electrical_properties.generator_photos[index];
    photo['ind'] = index;
    generatorTypePhotos.push(photo);
  }
  const generatorTypePhotosEmpty = generatorTypePhotos.length === 0;
  const handleFileDialogOpen = (key) => {
    if (key === 'generator_Photos') {
      setDialogTitle('one Photo of Generator');
      setImageSectionKey(generatorTypeTag);
    }
    setOpenFileDialog(true);
  };

  const uploadImage = (file) => {
    if (file) {
      const images = clone(generatorTypePhotos);
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const date = Date.now();
      const name = `${applicationDetail.permit_id}_${date}${extension}`;
      const currentVersion = get(applicationDetail, 'version.current_version', 'NEW');
      images.push({ loading: true, original_file_name: '', tag: 'Generator Photos', currentVersion: currentVersion });
      handleElectricalChangeChange(images, 'generator_photos');
      props.getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) => {
          props.putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              images.splice(0, 1);
              images.push({
                file_name: name,
                original_file_name: file.name,
                tag: 'Generator Photos',
                file_size: file.size / 1024 / 1024,
                versions: [currentVersion],
              });
              handleElectricalChangeChange(images, 'generator_photos');
            },
            failureCbS3: () => {
              images.splice(0, 1);
              handleElectricalChangeChange(images, 'generator_photos');
            },
          });
        },
        failureCb: () => {},
      });
    }
  };

  const deleteGeneratorPhotos = () => {
    handleElectricalChangeChange([], 'generator_photos');
  };
  const handleElectricalChangeChange = (value, key, fromOtherOption = false, skipValidation = false) => {
    if (fromOtherOption) value = `Other-${value}`;
    const data = clone(electrical_properties);
    data[key] = value;
    handleChange(data, 'electrical_properties', { additionalKey: key }, skipValidation);
  };

  const resetGeneratorValue = () => {
    const data = clone(electrical_properties);
    let resetValueArray = ['generator_connection', 'generator_manufacturer', 'generator_model', 'generator_quantity'];
    resetValueArray.forEach((item) => {
      data[item] = null;
    });
    data['generator_photos'] = [];
    data['existing_generator'] = 'No';
    handleChange(data, 'electrical_properties', { additionalKey: 'existing_generator' }, false);
  };

  const handlePropertiesChange = useCallback(
    (value, key) => {
      if (key === 'existing_generator' && value === 'No') {
        resetGeneratorValue();
      } else {
        handleChange({ ...electrical_properties, [key]: value }, 'electrical_properties', {
          additionalKey: key,
        });
      }
    },
    [electrical_properties, handleChange]
  );

  const getErrorText = (key) => (get(errors, `${key}.error`) ? get(errors, `${key}.errorMessage`) : '');
  return (
    <div className={classes.root} id="electrical_properties">
      <PanelTemplate header="Generator" className={classes.panelContainer}>
        <Box className={clsx(classes.electricalContainer, permitFilesAvailable && classes.disableForm)}>
          <Grid className={classes.gridContainer} container>
            <Grid className={classes.inputItemWrapper} item sm={12} md={6}>
              <Box id={'existing_generator'} className={classes.itemsHolder}>
                <FormControl component="div">
                  <FormLabel component="legend">
                    <Label className={classes.labelColor} text="Do you have existing Generator?" isRequired={true} />
                  </FormLabel>

                  <RadioGroup
                    value={existing_generator}
                    aria-label="Do you have existing Generator?"
                    name="customized-radios"
                    row
                    onChange={(e) => handlePropertiesChange(e.target.value, 'existing_generator')}
                  >
                    <FormControlLabel value={'Yes'} control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value={'No'} control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                  {getErrorText('existing_generator') && get(errors, `existing_generator.error`) && (
                    <FormHelperText className={classes.error}>{getErrorText('existing_generator')}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Grid>
            {existing_generator === 'Yes' && (
              <Grid className={classes.inputItemWrapper} item sm={12} md={6}>
                <Box className={classes.itemsHolder}>
                  <FormControl component="div">
                    <FormLabel component="legend">
                      <Label className={classes.labelColor} text="How Generator is connected?" isRequired={true} />
                    </FormLabel>

                    <RadioGroup
                      value={generator_connection}
                      aria-label="Do you have existing Generator?"
                      name="customized-radios"
                      row
                      onChange={(e) => handlePropertiesChange(e.target.value, 'generator_connection')}
                    >
                      <FormControlLabel
                        value={'Branch Circuit Breaker'}
                        control={<Radio color="primary" />}
                        label="Branch Circuit Breaker"
                      />
                      <FormControlLabel
                        value={'Whole Home Backup'}
                        control={<Radio color="primary" />}
                        label="Whole Home Backup"
                      />
                    </RadioGroup>
                    {getErrorText('generator_connection') && get(errors, `generator_connection.error`) && (
                      <FormHelperText className={classes.error}>{getErrorText('generator_connection')}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Grid>
            )}
          </Grid>
          {existing_generator === 'Yes' && (
            <Box>
              <Box className={classes.array}>
                <Box
                  className={clsx(
                    classes.root,
                    permitFilesAvailable && classes.disableForm,
                    classes.generatorPhotoSection
                  )}
                >
                  <Box className={classes.arrayBox}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <TextField
                            id="generator_type"
                            type="text"
                            className={clsx(classes.inputFieldSize, classes.customLabel)}
                            label={<Label className={classes.labelColor} text="type" isRequired />}
                            value={'Generator'}
                            onChange={(e) => handleElectricalChangeChange('generator_type', e.target.value, false)}
                            error={!isEmpty(getErrorText('generator_type'))}
                            helperText={getErrorText('generator_type')}
                            disabled={true}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <Autocomplete
                            className={classes.inputFieldSize}
                            id="generator_manufacturer"
                            options={mostUsedValues['generator_manufacturer']}
                            value={generator_manufacturer}
                            onChange={(e, value) =>
                              handleElectricalChangeChange(value, 'generator_manufacturer', false)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={get(errors, `generator_manufacturer.error`)}
                                helperText={getErrorText('generator_manufacturer')}
                                label={<Label text={'Manufacturer'} isRequired />}
                                onChange={(e) =>
                                  handleElectricalChangeChange(e.target.value, 'generator_manufacturer', false)
                                }
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <Autocomplete
                            className={classes.inputFieldSize}
                            id="generator_model"
                            options={mostUsedValues['generator_model']}
                            value={generator_model}
                            onChange={(e, value) => handleElectricalChangeChange(value, 'generator_model', false)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={get(errors, `generator_model.error`)}
                                helperText={getErrorText('generator_model')}
                                label={<Label text={'Model'} isRequired />}
                                onChange={(e) => handleElectricalChangeChange(e.target.value, 'generator_model', false)}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <TextField
                            id="generator_quantity"
                            type="number"
                            className={clsx(classes.inputFieldSize, classes.customLabel)}
                            label={<Label className={classes.labelColor} text="Quantity" isRequired />}
                            value={generator_quantity || 1}
                            onChange={(e) => handleElectricalChangeChange('generator_quantity', e.target.value, false)}
                            error={!isEmpty(getErrorText('generator_quantity'))}
                            helperText={getErrorText('generator_quantity')}
                            disabled={true}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className={classes.generatorPhotos}>
                          <Grid item xs={12} sm={12} md={6}>
                            <Box className={classes.generatorPhotoSection}>
                              <AddPhotoSection
                                classes={classes}
                                deletePhotos={deleteGeneratorPhotos}
                                keyValue="generator_photos"
                                photos={generatorTypePhotos}
                                photosEmpty={generatorTypePhotosEmpty}
                                handleFileDialogOpen={handleFileDialogOpen}
                              />
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </PanelTemplate>
      <FileUploadDialog
        type="Photo"
        title={dialogTitle}
        otherDialog={true}
        imageSectionKey={imageSectionKey}
        imageKey="generator_photos"
        fireUpload={uploadImage}
        open={openFileDialog}
        setOpen={() => setOpenFileDialog(!openFileDialog)}
        acceptableFileFormat={fileUtils.mediaType}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  mostUsedValues: state.permitReducer.mostUsedValues,
  otherUserSelectionData: state.permitReducer.otherUserSelectionData,
});

export default connect(mapStateToProps)(Generator);
