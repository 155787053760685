import React from 'react';
import { Label } from '../../../../common/Label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { get, isEmpty } from '../../../../../utils/lodash';
import { statesList } from '../../../../../containers/Admin/constants';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { Box, Grid, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:last-child': {
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  }
}));

const BasicDetailsSection = props => {
  const classes = useStyles();
  const { sectionLabel, errorData, basicDetails, handleBasicDetails, utilityList, loading, readOnly } = props;
  const { name = '', state = '' } = basicDetails;

  return (
    <Box className={classes.subToot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <Autocomplete
                className={classes.inputField}
                id="state"
                disableClearable={true}
                disabled={readOnly}
                options={statesList}
                value={state}
                onChange={(e, value) => handleBasicDetails(e, 'state', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!isEmpty(get(errorData, 'state'))}
                    helperText={get(errorData, 'state')}
                    label={<Label text={'State'} isRequired />}
                  />
                )}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="name"
                disabled={isEmpty(state) || readOnly}
                value={name}
                loading={loading}
                options={!isEmpty(basicDetails['state']) ? utilityList.map(option => option.lseName).sort() : []}
                onChange={(e, value) => handleBasicDetails(e, 'name', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    error={!isEmpty(get(errorData, 'name'))}
                    helperText={get(errorData, 'name')}
                    label={<Label text={'Utility name'} isRequired capitalizeLabelText={false}/>}
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default BasicDetailsSection;
