import { Box, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { preventDefaultDecimal } from '../../../../../../containers/Admin/helper';
import HelpTextIcon from '../../../../../common/HelpTextIcon';
import { Label } from '../../../../../common/Label';
import { MultipleSelectDropdown } from '../../../../../common/MultipleSelectDropdown';
import CustomPanel from '../../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../../common/SingleSelectDropdown';
import {
  AC_DISCONNECT_REQUIRED_OPTIONS_ELECTRICAL,
  CONDUIT_ALLOWED_OPTIONS,
  METER_SOCKET_ADAPTOR_PROGRAM_OPTIONS,
  PV_METER_LOCATION_OPTIONS,
  PV_METER_RRQUIREMENT_OPTIONS,
  SURGE_PROTECTION_REQUIRED_OPTIONS,
  YES_NO_OPTIONS,
} from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  addressWrapperWithDropdown: {
    flexDirection: 'row'
  },
  inputFieldDropdown: {
    width: '1%',
    minWidth: theme.spacing(1),
    margin: theme.spacing(5, -1, 0, 0),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  inputFieldTextDropdown: {
    width: '99%',
    minWidth: theme.spacing(0),
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  longLabel: {
    '@media (max-width: 1280px)': {
      '& .MuiInputLabel-outlined': {
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -16px) scale(0.75)'
        }
      }
    }
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  iconWrapper: {
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  conditionalLongLabel: {
    transform: 'translate(0, -20px)',
  },
  helpText: {
    fontWeight: 400,
    fontSize: theme.spacing(1.5),
    color: '#9E9E9E',
    marginLeft: theme.spacing(1),
  },
}));

const ElectricalDesignRequirementsSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleElectricalDesignRequirements,
    electricalDesignRequirements,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    min_system_size_dc = '',
    max_system_size_ac = '',
    three_line_diagram = '',
    surge_protection_required = '',
    cec_ac_rating_required = '',
    pv_meter_location = '',
    pv_meter_requirement = [],
    ac_disconnect_required_electrical = [],
    conduit_allowed = [],
    meter_socket_adaptor_program_allowed = '',
    maximum_fuse_rating_of_disconnect = '',
    disconnect_location = '',
    conduit_requirements = '',
    ground_wire_requirements = '',
    equipment_sequence = '',
    battery_requirements = '',
    additional_requirements_electrical = '',
  } = electricalDesignRequirements;

  const no_meter_socket_adaptor_program_allowed = isEmpty(meter_socket_adaptor_program_allowed) || meter_socket_adaptor_program_allowed === 'no';
  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="min_system_size_dc"
                type="number"
                className={classes.inputField}
                label={<Label text={'Minimum system size allowed (DC) (kW)'} capitalizeLabelText={false} />}
                value={min_system_size_dc ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'min_system_size_dc')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'min_system_size_dc'))}
                helperText={get(errorData, 'min_system_size_dc')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="max_system_size_ac"
                type="number"
                className={classes.inputField}
                label={<Label text={'Maximum system size allowed (AC) (kW)'} capitalizeLabelText={false} />}
                value={max_system_size_ac ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'max_system_size_ac')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'max_system_size_ac'))}
                helperText={get(errorData, 'max_system_size_ac')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'3 line diagram required'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="three_line_diagram"
                fieldValue={three_line_diagram ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Surge protection required in case of MSP upgrade'} capitalizeLabelText={false} />}
                dropDownOptions={SURGE_PROTECTION_REQUIRED_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="surge_protection_required"
                fieldValue={surge_protection_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'CEC AC rating required in plan set'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="cec_ac_rating_required"
                fieldValue={cec_ac_rating_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'PV meter location'} capitalizeLabelText={false} />}
                dropDownOptions={PV_METER_LOCATION_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="pv_meter_location"
                fieldValue={pv_meter_location ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'PV meter requirement'} capitalizeLabelText={false} />}
                dropDownOptions={PV_METER_RRQUIREMENT_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="pv_meter_requirement"
                fieldValue={pv_meter_requirement ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'AC disconnect required'} capitalizeLabelText={false} />}
                dropDownOptions={AC_DISCONNECT_REQUIRED_OPTIONS_ELECTRICAL}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="ac_disconnect_required_electrical"
                fieldValue={ac_disconnect_required_electrical ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <MultipleSelectDropdown
                dropDownLabel={<Label text={'Conduit allowed'} capitalizeLabelText={false} />}
                dropDownOptions={CONDUIT_ALLOWED_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="conduit_allowed"
                fieldValue={conduit_allowed ?? []}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper} style={{ justifyContent: 'flex-start' }}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Meter socket adaptor program allowed'} capitalizeLabelText={false} />}
                dropDownOptions={METER_SOCKET_ADAPTOR_PROGRAM_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDesignRequirements}
                fieldId="meter_socket_adaptor_program_allowed"
                fieldValue={meter_socket_adaptor_program_allowed ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={clsx(classes.addressWrapper, classes.addressWrapperWithDropdown)}>
              <TextField
                id="maximum_fuse_rating_of_disconnect"
                type="number"
                className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
                label={<Label text={'Maximum fuse rating of disconnect when meter socket adaptor program used (A)'} className={classes.conditionalLongLabel} capitalizeLabelText={false} />}
                value={maximum_fuse_rating_of_disconnect ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'maximum_fuse_rating_of_disconnect')}
                disabled={readOnly || !stateSelected || no_meter_socket_adaptor_program_allowed}
                onKeyPress={(e) => preventDefaultDecimal(e)}
                error={!isEmpty(get(errorData, 'maximum_fuse_rating_of_disconnect'))}
                helperText={get(errorData, 'maximum_fuse_rating_of_disconnect')}
              />
              <HelpTextIcon
                message={
                  'Disabled if "Meter socket adaptor program allowed" field remains blank or is set to "No"'
                }
                position="top"
                btnClassName={clsx(classes.inputFieldDropdown)}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Disconnect location when meter socket adaptor program used"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly || no_meter_socket_adaptor_program_allowed}
                value={disconnect_location ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'disconnect_location')}
              />
              <Typography className={classes.helpText}>Disabled if "Meter socket adaptor program allowed" field remains blank or is set to "No"</Typography>
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Conduit requirements when meter socket adaptor program used"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly || no_meter_socket_adaptor_program_allowed}
                value={conduit_requirements ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'conduit_requirements')}
              />
              <Typography className={classes.helpText}>Disabled if "Meter socket adaptor program allowed" field remains blank or is set to "No"</Typography>
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField, classes.longLabel)}
                label="Ground wire requirements between meter adaptor and fused disconnect when meter socket adaptor program used"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly || no_meter_socket_adaptor_program_allowed}
                value={ground_wire_requirements ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'ground_wire_requirements')}
              />
              <Typography className={classes.helpText}>Disabled if "Meter socket adaptor program allowed" field remains blank or is set to "No"</Typography>
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Equipment sequence"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={equipment_sequence ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'equipment_sequence')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Battery requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={battery_requirements ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'battery_requirements')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_electrical ?? ''}
                onChange={(e) => handleElectricalDesignRequirements(e, 'additional_requirements_electrical')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDesignRequirementsSection;
