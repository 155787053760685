import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { preventDefault } from '../../../../../../containers/Admin/helper';
import { Label } from '../../../../../common/Label';
import CustomPanel from '../../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../../common/SingleSelectDropdown';
import { YES_NO_OPTIONS } from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
}));

const SiteAndRoofPlanSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleSiteAndRoofPlan,
    siteAndRoofPlan,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
  } = props;
  const {
    min_clearance_utility = '',
    min_clearance_gas = '',
    offset = '',
    display_gas_meter = '',
    design_required = '',
    additional_requirements_site_and_roof_plan = '',
  } = siteAndRoofPlan;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id="min_clearance_utility"
                type="number"
                className={classes.inputField}
                label={<Label text={'Minimum clearance around utility meter (in)'} capitalizeLabelText={false} />}
                value={min_clearance_utility ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'min_clearance_utility')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'min_clearance_utility'))}
                helperText={get(errorData, 'min_clearance_utility')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="min_clearance_gas"
                type="number"
                className={classes.inputField}
                label={<Label text={'Minimum clearance around gas meter (in)'} capitalizeLabelText={false} />}
                value={min_clearance_gas ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'min_clearance_gas')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'min_clearance_gas'))}
                helperText={get(errorData, 'min_clearance_gas')}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id="offset"
                type="number"
                className={classes.inputField}
                label={<Label text={'Offset around electrical mast/drop (in)'} capitalizeLabelText={false} />}
                value={offset ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'offset')}
                disabled={readOnly || !stateSelected}
                onKeyPress={(e) => preventDefault(e)}
                error={!isEmpty(get(errorData, 'offset'))}
                helperText={get(errorData, 'offset')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper} style={{ justifyContent: 'flex-start' }}>
            <Box className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Display gas meter on the layout'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="display_gas_meter"
                fieldValue={display_gas_meter ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Design required on non-permitted structures'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSiteAndRoofPlan}
                fieldId="design_required"
                fieldValue={design_required ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box
              id="additional_requirements_site_and_roof_plan"
              className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}
            >
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_site_and_roof_plan ?? ''}
                onChange={(e) => handleSiteAndRoofPlan(e, 'additional_requirements_site_and_roof_plan')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default SiteAndRoofPlanSection;
