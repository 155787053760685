import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { isEmpty } from 'lodash';
import HelpTextIcon from '../../../../../common/HelpTextIcon';
import { Label } from '../../../../../common/Label';
import CustomPanel from '../../../../../common/PanelTemplateWithMandatory';
import { SingleSelectDropdown } from '../../../../../common/SingleSelectDropdown';
import { AC_DISCONNECT_REQUIRED_OPTIONS_SYSTEM_EXPANSION, YES_NO_OPTIONS } from '../constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '&:last-child': {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  lastRowWrapper: {
    marginRight: '5%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 0,
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  addressWrapperWithDropdown: {
    flexDirection: 'row'
  },
  conditionalLongLabel: {
    transform: 'translate(0, -20px)',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: '460px',
  },
  iconWrapper: {
    position: 'absolute',
    right: '-12px',
    top: '40px',
  },
}));

const SystemExpansionSection = (props) => {
  const classes = useStyles();
  const {
    getErrorText,
    getHelperText,
    handleSystemExpansion,
    systemExpansion,
    sectionLabel,
    readOnly,
    stateSelected,
    errorData,
    meterSocketAdaptorProgramAllowed,
  } = props;
  const {
    ac_disconnect_required_system_expansion = '',
    single_point_interconnection_allowed = '',
    multiple_interconnection_allowed = '',
    additional_requirements_system_expansion = '',
  } = systemExpansion;

  const disableMultipleInterconnectionAllowed = isEmpty(meterSocketAdaptorProgramAllowed) || meterSocketAdaptorProgramAllowed === 'no';

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'AC disconnect required'} capitalizeLabelText={false} />}
                dropDownOptions={AC_DISCONNECT_REQUIRED_OPTIONS_SYSTEM_EXPANSION}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSystemExpansion}
                fieldId="ac_disconnect_required_system_expansion"
                fieldValue={ac_disconnect_required_system_expansion ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Single point of interconnection allowed'} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSystemExpansion}
                fieldId="single_point_interconnection_allowed"
                fieldValue={single_point_interconnection_allowed ?? ''}
                readOnly={readOnly || !stateSelected}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Multiple interconnection allowed when meter socket adaptor program used'} className={classes.conditionalLongLabel} capitalizeLabelText={false} />}
                dropDownOptions={YES_NO_OPTIONS}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleSystemExpansion}
                fieldId="multiple_interconnection_allowed"
                fieldValue={multiple_interconnection_allowed ?? ''}
                readOnly={readOnly || !stateSelected || disableMultipleInterconnectionAllowed}
              />
              <HelpTextIcon
                message={
                  'Disabled if "Meter socket adaptor program allowed" field remains blank or is set to "No" under Design requirements (Electrical) section'
                }
                position="top"
                btnClassName={classes.iconWrapper}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box
              id="additional_requirements_system_expansion"
              className={`${classes.addressWrapper} ${classes.lastRowWrapper}`}
            >
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Additional requirements"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={additional_requirements_system_expansion ?? ''}
                onChange={(e) => handleSystemExpansion(e, 'additional_requirements_system_expansion')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box >
  );
};

export default SystemExpansionSection;
