import clsx from 'clsx';
import React, { useState } from 'react';
import { Label } from '../../../common/Label';
import { get, hasIn, isEmpty } from '../../../../utils/lodash';
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@material-ui/core';
import CustomPanel from '../../../common/PanelTemplateWithMandatory';
import CloseIcon from '@material-ui/icons/Close';
import { AddCircleOutline } from '@material-ui/icons';
import HelpTextIcon from '../../../common/HelpTextIcon';
import { SingleSelectDropdown } from '../../../common/SingleSelectDropdown';
import { AHJ_REFERENCE_STATUS_LIST } from '../../../../containers/Admin/constants';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  dataSourceField: {
    marginTop: theme.spacing(6),
  },
  inputFieldWrapper: {
    width: '37.5%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  multiSelect: {
    whiteSpace: 'normal',
  },
  actionSection: {
    padding: theme.spacing(8, 0, 4, 0),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
    },
  },
  submitButton: {
    marginLeft: theme.spacing(),
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
  alert: {
    justifyContent: 'center',
    width: 'auto',
    marginTop: theme.spacing(1),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkboxLabel: {
    margin: theme.spacing(4, 0, -1, -0.3),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  referenceStatusField: {
    margin: theme.spacing(9, 0, 0, 1),
    '& .MuiFormControl-root': {
      width: `calc(100% - ${theme.spacing(1) + 13}px)`,
    },
  },
  referenceFilesField: {
    margin: theme.spacing(9, 1, 0),
  },
  referenceFilesLabel: {
    margin: theme.spacing(1, 0),
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(30),
    marginRight: theme.spacing(-1),
    overflow: 'scroll',
  },
  errorText: {
    fontSize: theme.spacing(1.75),
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const ReferenceInformationSectionOldUI = (props) => {
  const classes = useStyles();
  const {
    readOnly,
    errorData,
    sectionLabel,
    referenceInformation,
    handleReferenceInformation,
    referenceFilesUploadError,
    handleFilesUpload,
    basicDetails,
    fileIcon,
    deleteFile,
    handleFileDownload,
  } = props;
  const { reference = '', reference_notes = '', reference_status = '' } = referenceInformation;
  const [confirmation, setConfirmation] = useState(false);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  return (
    <Box className={classes.subRoot}>
      <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(selectedFileIndex);
              setConfirmation(false);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setConfirmation(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label={<Label text={'Reference'} />}
                value={reference}
                onChange={(e) => handleReferenceInformation(e, 'reference')}
                error={hasIn(errorData, 'reference')}
                helperText={get(errorData, 'reference')}
                multiline
                maxRows={20}
                minRows={5}
                variant="outlined"
                disabled={isEmpty(basicDetails.state) || readOnly}
              />
              <Box id="referenceStatus" className={classes.referenceStatusField}>
                <SingleSelectDropdown
                  dropDownLabel={<Label text={'Reference Status'} isRequired />}
                  dropDownOptions={AHJ_REFERENCE_STATUS_LIST.slice(1)}
                  getErrorText={(fieldId) => hasIn(errorData, fieldId)}
                  getHelperText={(fieldId) => get(errorData, fieldId)}
                  handleField={(e) => handleReferenceInformation(e, 'reference_status')}
                  fieldId="reference_status"
                  fieldValue={reference_status}
                  readOnly={readOnly}
                />
                {reference_status === 'REFERENCE_FOUND' && (
                  <HelpTextIcon
                    message="When checked, Permit package designer will refer to parent record as
                  details are not available at AHJ level. For Eg: When this field is checked for AHJ
                  record at City level, designer will refer to AHJ record at County level, and next
                  to State level"
                    position="left-end"
                    btnClassName={classes.button}
                  />
                )}
              </Box>
            </Box>
            <Box id="reference_notes" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label={
                  <Label
                    text={'Reference Notes'}
                    isRequired={reference_status === 'AHJ_DATA_POPULATED_USING_REFERENCE_JOB'}
                  />
                }
                value={reference_notes}
                onChange={(e) => handleReferenceInformation(e, 'reference_notes')}
                error={hasIn(errorData, 'reference_notes')}
                helperText={get(errorData, 'reference_notes')}
                multiline
                maxRows={20}
                minRows={5}
                variant="outlined"
                disabled={isEmpty(basicDetails.state) || readOnly}
              />
              <Box id="referenceFiles" className={classes.referenceFilesField}>
                <Label text="Reference Files" className={classes.referenceFilesLabel} />
                {referenceInformation.reference_files !== null && referenceInformation.reference_files.length > 0 && (
                  <Box className={classes.filesListWrapper}>
                    {referenceInformation.reference_files
                      .slice()
                      .reverse()
                      .map((file, i) => (
                        <Box
                          key={file.display_file_name + i}
                          id={file.display_file_name}
                          className={classes.fileAttachment}
                        >
                          <Box className={classes.fileNameIconWrapper} onClick={() => handleFileDownload(file)}>
                            <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                              {fileIcon(file.file_name)}
                            </IconButton>
                            <Box>
                              <Typography title={file.original_file_name}>{file.display_file_name}</Typography>
                              <Typography variant="caption">{file.file_size}</Typography>
                            </Box>
                          </Box>
                          {!(isEmpty(basicDetails.state) || readOnly) && (
                            <IconButton className={classes.popupCloseBtn}>
                              <CloseIcon
                                onClick={() => {
                                  setSelectedFileIndex(i);
                                  setConfirmation(true);
                                }}
                                className={classes.popupCloseIcon}
                              />
                            </IconButton>
                          )}
                        </Box>
                      ))}
                  </Box>
                )}
                {/* for displaying the error */}
                {referenceFilesUploadError.msg !== '' && (
                  <Box>
                    <Typography className={classes.errorText}>{referenceFilesUploadError.msg}</Typography>
                  </Box>
                )}
                <Button color="primary" component="label" disabled={isEmpty(basicDetails.state) || readOnly}>
                  <AddCircleOutline />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    id="referenceFiles"
                    name="referenceFiles"
                    onChange={(e) => handleFilesUpload(e)}
                    multiple
                  />
                  Add document
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ReferenceInformationSectionOldUI;
