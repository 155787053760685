import { getValue, setValue } from './localStorage';
import history from '../routes/history';
import { isEmpty, get } from '../utils/lodash';
import JsonWebToken from 'jsonwebtoken';
import { INSTALLER_STATES, PERMIT_SERVICE_ROLES, SOURCE, METER_TO_INCH_RATIO } from './constants';
import JSZip from 'jszip';
import settings from './settings';

export const getToken = () => getValue('_enlighten_manager_token');

export const setToken = (value) => setValue('_enlighten_manager_token', value);

export const getCookie = (cookie_name) => getValue(cookie_name)

export const forwardTo = (location) => history.push(location);

export const forwardToWithState = (location, state) => history.push(location, state);

const defaultMessage = 'To download files, please disable popup blocker in your browser';
export const handlePopupBlocker = (windowObj, message = defaultMessage) => {
  if (windowObj == null || typeof windowObj == 'undefined') alert(message);
  else windowObj.focus();
};

export const applicationStage = (stepcompleted) => {
  if (stepcompleted === 1) {
    forwardTo('personalDetails');
  } else if (stepcompleted === 21) {
    forwardTo('personalDetailsQuestions');
  } else if (stepcompleted === 22) {
    forwardTo('uploadDocuments');
  } else if (stepcompleted === 3) {
    forwardTo('serviceCharge');
  } else if (stepcompleted === 4) {
    forwardTo('applicationTrack');
  }
};

export const getStatus = (row, value, page = '') => {
  let statusValue;
  if (page === 'equipment') statusValue = isEmpty(get(row, value)) ? '' : get(row, value).toUpperCase();
  else statusValue = get(row, value);
  switch (statusValue) {
    case 'INCOMPLETE':
      return 'Incomplete';
    case 'PENDING':
      return 'Approval Pending';
    case 'APPROVED':
      return 'Approved';
    case 'REJECTED':
      return 'Rejected';
    case 'OUTDATED':
      return 'Outdated';
    default:
      return 'NA';
  }
};

export const downloadZipFromUrl = (attachmentDetails, zipFileName, isSGProject = false) => {
  var zip = new JSZip();
  var count = 0;
  attachmentDetails = attachmentDetails.filter((file) => file.fileUrl !== '');

  attachmentDetails.forEach(function (file) {
    var fileName = file.fileName;
    var fileUrl = file.fileUrl;
    let finalUrl = fileUrl;
    if (isSGProject) {
      finalUrl = settings.getSolargrafS3Domain();
      if (fileUrl.includes('/static/'))
        finalUrl = finalUrl + fileUrl.split('/').slice(4).join('/');
      else
        finalUrl = finalUrl + fileUrl.split('/').slice(3).join('/');
      
        finalUrl = `${finalUrl}?cache-buster=${Date.now()}`;
    }
    if (!fileName.includes('.')) {
      fileName = fileName + getExtensionFromFileUrl(fileUrl);
    }
    fetch(finalUrl).then((response) =>
      response.arrayBuffer().then(function (buffer) {
        zip.file(fileName, buffer, { binary: true });
        count++;
        if (count === attachmentDetails.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            const url = window.URL.createObjectURL(new Blob([content]));
            const link1 = document.createElement('a');
            link1.href = url;
            link1.download = zipFileName;
            link1.click();
          });
        }
      })
    );
  });
};

// returns file's extenstion from file URL e.g. https://test.com/abc.pdf?param=test -> .pdf
export const getExtensionFromFileUrl = (url) => {
  url = url.includes('?') ? url.split('?', 2)[0] : url;
  return '.' + url.split('.').pop();
};

export const getReferenceStatus = (row) => {
  const { reference_status: statusValue } = row;
  switch (statusValue) {
    case 'TODO':
      return 'TODO';
    case 'REFERENCE_COMPLETE':
      return 'Reference Complete';
    case 'REFERENCE_FOUND':
      return 'Reference Found (Use parent)';
    case 'REFERENCE_NOT_FOUND':
      return 'Reference not Found Online';
    case 'AHJ_DATA_POPULATED_USING_REFERENCE_JOB':
      return 'AHJ data populated using reference job';
    case 'AHJ_DATA_NOT_AVAILABLE':
      return 'AHJ data not available (Online/Offline/Job Reference)';
    case 'AHJ_DOES_NOT_EXIST':
      return 'AHJ does not exist';
    default:
      return 'NA';
  }
};

export const constructQueryString = (queryObj, ignoreEmptyParams = false) => {
  let queryString = '';
  Object.keys(queryObj).forEach((queryKey, index) => {
    const queryConnector = queryString === '' ? '?' : '&';
    if (!ignoreEmptyParams || !isEmpty(queryObj[queryKey])) {
      queryString = queryString + `${queryConnector}${queryKey}=${queryObj[queryKey]}`;
    }
  });
  return queryString;
};

const MAX_OUTPUT_PER_PANEL = 400;

const MIN_OUTPUT_PER_PANEL = 200;

// solarSystemSize in kW
export const getAcceptablePanelsRange = (solarSystemSize) => {
  const lowerValue = Math.floor((solarSystemSize * 1000) / MAX_OUTPUT_PER_PANEL);
  const upperValue = Math.floor((solarSystemSize * 1000) / MIN_OUTPUT_PER_PANEL);
  return { min: lowerValue, max: upperValue };
};


export const containsStatus = (statuses, stateId) => {
  const status = statuses && statuses.find((state) => state.state_id === stateId);
  if (status) {
    return true;
  }
  return false;
};

export const downloadFileSG = (linkUrl, fileName) => {
  let finalUrl = settings.getSolargrafS3Domain();
  if (linkUrl.includes('/static/'))
      finalUrl = finalUrl + linkUrl.split('/').slice(4).join('/');
  else
      finalUrl = finalUrl + linkUrl.split('/').slice(3).join('/');

  finalUrl = `${finalUrl}?cache-buster=${Date.now()}`;

  fetch(finalUrl, { method: 'GET' }).then((response) =>
    response.arrayBuffer().then(function (buffer) {
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName + '.' + linkUrl.split('/').pop().split('.').pop();
      link.click();
    })
  );
};

export const formatString = (value) => {
  if (isEmpty(value) || value === '_') {
    return '--';
  }
  return value.replace(/[ +*/.,<>%?:;|&#()\\{}"\[\]]/g, '');
};

export const isPermitSetAvailable = (app) => {
  return containsStatus(get(app, 'installer_statuses', []) || [], INSTALLER_STATES.PERMIT_SET_AVAILABLE.id);
};

export const isAdmin = (permitRole) => {
  return (
    permitRole === PERMIT_SERVICE_ROLES.APPLICATION_ADMIN ||
    permitRole === PERMIT_SERVICE_ROLES.SERVICE_ADMIN ||
    permitRole === PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN ||
    permitRole === PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_ADMIN
  );
};

export const isInstaller = (permitRole) => {
  return isEmpty(permitRole) || permitRole === PERMIT_SERVICE_ROLES.INSTALLER;
};

export const isSolargrafApplication = (app) => {
  return get(app, 'source') === SOURCE.SOLARGRAF;
};

export const getFile = (fName, getPresingedURL) => {
  return new Promise((resolve) => {
    getPresingedURL({
      file_name: fName,
      http_method: 'GET',
      successCb: (presignedURL) => {
        resolve(presignedURL);
      },
      failureCb: () => {
        resolve(false);
      },
    });
  });
};

export const downloadFile = async (fileUrl, fileName, cb = () => { }) => {
  if (fileUrl) {
    const url = await fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    cb();
  }
};

export const capitalizeFirstLetter = (value) =>
  value ? value.slice(0, 1).toUpperCase() + value.slice(1).toLowerCase() : value;

export const inchToMeter = (value) => {
  if (typeof value === 'string') {
    value = value.trim();
  }
  return Number(value) / METER_TO_INCH_RATIO;
};
