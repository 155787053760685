import { isEmpty, isEqual, throttle } from '../../utils/lodash';
import { INSTALLER_STATES } from '../../utils/constants';
import { REQUIRED_SERVICES, fileTypes } from './constants';

const addStatus = (status, statuses) => {
  statuses.push({
    state_id: status.id,
    state_name: status.name,
  });
};

const handleInProgressStates = (statuses, stateId) => {
  if (stateId === INSTALLER_STATES.NEW_APPLICATION_RECEIVED.id) {
    addStatus(INSTALLER_STATES.APPLICATION_UNDER_REVIEW, statuses);
    addStatus(INSTALLER_STATES.PERMIT_SET_AVAILABLE, statuses);
  } else if (stateId === INSTALLER_STATES.APPLICATION_UNDER_REVIEW.id) {
    addStatus(INSTALLER_STATES.PERMIT_SET_AVAILABLE, statuses);
  } else if (stateId === INSTALLER_STATES.REWORK_REQUEST_SUBMITTED.id) {
    addStatus(INSTALLER_STATES.REWORK_SUBMITTED, statuses);
  } else if (stateId === INSTALLER_STATES.STAMPING_SUBMITTED.id) {
    addStatus(INSTALLER_STATES.STAMPING_SET_AVAILABLE, statuses);
  }
};

export const addNextInstallerStatuses = (statuses) => {
  if (isEmpty(statuses)) {
    addStatus(INSTALLER_STATES.NEW_APPLICATION_RECEIVED, statuses);
    addStatus(INSTALLER_STATES.APPLICATION_UNDER_REVIEW, statuses);
    addStatus(INSTALLER_STATES.PERMIT_SET_AVAILABLE, statuses);
    return statuses;
  }
  const lastStatus = statuses[statuses.length - 1];
  if (
    lastStatus.state_id === INSTALLER_STATES.MORE_INFO_REQUESTED.id ||
    lastStatus.state_id === INSTALLER_STATES.MORE_INFO_RECEIVED.id ||
    lastStatus.state_id === INSTALLER_STATES.APPLICATION_EDITED.id
  ) {
    if (lastStatus.state_id === INSTALLER_STATES.MORE_INFO_REQUESTED.id) {
      addStatus(INSTALLER_STATES.MORE_INFO_RECEIVED, statuses);
    }
    const lastReviewState = getLastReviewState(statuses);
    handleInProgressStates(statuses, lastReviewState.state_id);
  } else {
    handleInProgressStates(statuses, lastStatus.state_id);
  }
  return statuses;
};

/**
 * Returns the Previous state which is not included in More information requested, More information received and Application Edited
 * @param statuses List of Installer statuses to be used
 */
const getLastReviewState = (statuses) => {
  if (statuses) {
    for (let i = statuses.length - 1; i >= 0; i--) {
      if (
        statuses[i].state_id !== INSTALLER_STATES.MORE_INFO_REQUESTED.id &&
        statuses[i].state_id !== INSTALLER_STATES.MORE_INFO_RECEIVED.id &&
        statuses[i].state_id !== INSTALLER_STATES.APPLICATION_EDITED.id
      ) {
        return statuses[i];
      }
    }
  }
  return null;
};

export const fetchAddress = throttle((autocompleteService, request, successCb) => {
  if (autocompleteService) {
    autocompleteService.getPlacePredictions(request, (options) => options && successCb(options));
  }
}, 200);

/**
 * Returns true if featureName of type featureType is enabled, otherwise false.
 * @param featureList List of all features
 * @param featureName Name of feature that is needed
 * @param featureType Type of feature, ['company_id', 'email']
 */
export const isFeatureEnabled = (featureList, featureName, featureType) => {
  if (featureList === undefined) featureList = [{ is_enabled: false }];
  const feature = featureList.filter(
    (feature) => feature.feature_id === featureName && feature.feature_type === featureType
  );
  if (feature.length === 0) return false;
  else return feature[0].is_enabled;
};

// to get place id from a given address
export const fetchPlaceId = (autocompleteService, address, successCb, failureCb) => {
  if (autocompleteService && !isEmpty(address.input)) {
    autocompleteService.getPlacePredictions(address, (options) => {
      if (options && options.length === 1) {
        successCb(options[0].place_id);
      } else {
        failureCb();
      }
    });
  }
};

// to get county details using place id
export const fetchCounty = (geocoder, placeId, successCb, failureCb) => {
  geocoder.geocode({ placeId }, (res) => {
    if (res && res[0]) {
      let county = res[0].address_components.find((comp) => comp.types.includes('administrative_area_level_2'));
      county = county && county.long_name;
      successCb(county);
    } else {
      failureCb();
    }
  });
};

// check if all the permit services are purchased
export const areAllServicesPurchased = (
  requiredServices,
  reworkServices,
  purchasedServices,
  purchasedReworkServices,
  permitSetAvailable
) => {
  // carport installation services is not allowed to be purchased after permit set is received
  if (permitSetAvailable) {
    requiredServices = requiredServices.filter((s) => s.name !== REQUIRED_SERVICES.CARPORT_INSTALLATION);
  }
  return isEqual(
    requiredServices.length + reworkServices.length,
    purchasedServices.length + purchasedReworkServices.length
  );
};

export const isValidFileType = (file, acceptedFileExtensions) => {
  const allowedFileExtensions = acceptedFileExtensions.split(',').map((fileType) => fileType.trim().substring(1));
  const allowedFileTypes = allowedFileExtensions.map((fileExt) => fileTypes[fileExt] || []).flat();
  const lastDotPosition = file.name.lastIndexOf('.');
  const fileExtension =
    lastDotPosition > 0 && lastDotPosition < file.name.length - 1
      ? file.name.slice(lastDotPosition + 1).toLowerCase()
      : '';
  const fileType = file.type.toLowerCase();
  console.log('fileType: ', fileType);
  console.log('fileExtension: ', fileExtension);
  return allowedFileTypes.includes(fileType) || allowedFileExtensions.includes(fileExtension);
};
