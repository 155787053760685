export const CREATE_PROJECT_DETAILS_REQUEST = 'CREATE_PROJECT_DETAILS_REQUEST';
export const CREATE_PROJECT_DETAILS_SUCCESS = 'CREATE_PROJECT_DETAILS_SUCCESS';
export const CREATE_PROJECT_DETAILS_FAILURE = 'CREATE_PROJECT_DETAILS_FAILURE';
export const GET_PROJECT_DETAILS_REQUEST = 'GET_PROJECT_DETAILS_REQUEST';
export const GET_PROJECT_DETAILS_SUCCESS = 'GET_PROJECT_DETAILS_SUCCESS';
export const GET_PROJECT_DETAILS_FAILURE = 'GET_PROJECT_DETAILS_FAILURE';

export const UPDATE_PAYMENT_DETAILS_REQUEST = 'UPDATE_PAYMENT_DETAILS_REQUEST';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_FAILURE = 'UPDATE_PAYMENT_DETAILS_FAILURE';

export const GET_PERMIT_LISTING_REQUEST = 'GET_PERMIT_LISTING_REQUEST';
export const GET_PERMIT_LISTING_SUCCESS = 'GET_PERMIT_LISTING_SUCCESS';
export const GET_PERMIT_LISTING_FAILURE = 'GET_PERMIT_LISTING_FAILURE';

export const GET_PERMIT_COUNT_BY_INSTALLER_REQUEST = 'GET_PERMIT_COUNT_BY_INSTALLER_REQUEST';
export const GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS = 'GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS';
export const GET_PERMIT_COUNT_BY_INSTALLER_FAILURE = 'GET_PERMIT_COUNT_BY_INSTALLER_FAILURE';

export const SITE_SEARCH_REQUEST = 'SITE_SEARCH_REQUEST';
export const SITE_SEARCH_REQUEST_SUCCESS = 'SITE_SEARCH_REQUEST_SUCCESS';
export const SITE_SEARCH_REQUEST_FAILURE = 'SITE_SEARCH_REQUEST_FAILURE';
export const RESET_SITE_SEARCH_RESULT_REQUEST = 'RESET_SITE_SEARCH_RESULT_REQUEST';

export const GET_CHAT_INFO_REQUEST = 'GET_CHAT_INFO_REQUEST';
export const GET_CHAT_INFO_SUCCESS = 'GET_CHAT_INFO_SUCCESS';
export const GET_CHAT_INFO_FAILURE = 'GET_CHAT_INFO_FAILURE';
export const UPDATE_CHAT_STATUS_REQUEST = 'UPDATE_CHAT_STATUS_REQUEST';
export const UPDATE_CHAT_STATUS_SUCCESS = 'UPDATE_CHAT_STATUS_SUCCESS';
export const UPDATE_CHAT_STATUS_FAILURE = 'UPDATE_CHAT_STATUS_FAILURE';

export const SITE_DETAILS_REQUEST = 'SITE_DETAILS_REQUEST';
export const SITE_DETAILS_SUCCESS = 'SITE_DETAILS_SUCCESS';
export const SITE_DETAILS_FAILURE = 'SITE_DETAILS_FAILURE';
export const CREATE_MATERIAL_DETAILS_REQUEST = 'CREATE_MATERIAL_DETAILS_REQUEST';
export const CREATE_MATERIAL_DETAILS_SUCCESS = 'CREATE_MATERIAL_DETAILS_SUCCESS';
export const CREATE_MATERIAL_DETAILS_FAILURE = 'CREATE_MATERIAL_DETAILS_FAILURE';
export const GET_MATERIAL_DETAILS_REQUEST = 'GET_MATERIAL_DETAILS_REQUEST';
export const GET_MATERIAL_DETAILS_SUCCESS = 'GET_MATERIAL_DETAILS_SUCCESS';
export const GET_MATERIAL_DETAILS_FAILURE = 'GET_MATERIAL_DETAILS_FAILURE';
export const GET_PANEL_MANUFACTURER_LIST_REQUEST = 'GET_PANEL_MANUFACTURER_LIST_REQUEST';
export const GET_PANEL_MANUFACTURER_LIST_SUCCESS = 'GET_PANEL_MANUFACTURER_LIST_SUCCESS';
export const GET_PANEL_MANUFACTURER_LIST_FAILURE = 'GET_PANEL_MANUFACTURER_LIST_FAILURE';

export const GET_USER_SELECTION_REQUEST = 'GET_USER_SELECTION_REQUEST';
export const GET_USER_SELECTION_SUCCESS = 'GET_USER_SELECTION_SUCCESS';
export const GET_USER_SELECTION_FAILURE = 'GET_USER_SELECTION_FAILURE';
export const GET_OTHER_USER_SELECTION_REQUEST = 'GET_OTHER_USER_SELECTION_REQUEST';
export const GET_OTHER_USER_SELECTION_SUCCESS = 'GET_OTHER_USER_SELECTION_SUCCESS';
export const GET_OTHER_USER_SELECTION_FAILURE = 'GET_OTHER_USER_SELECTION_FAILURE';
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE';
export const GET_REWORK_SERVICES_REQUEST = 'GET_REWORK_SERVICES_REQUEST';
export const GET_REWORK_SERVICES_SUCCESS = 'GET_REWORK_SERVICES_SUCCESS';
export const GET_REWORK_SERVICES_FAILURE = 'GET_REWORK_SERVICES_FAILURE';
export const GET_SELECTED_REWORK_SERVICES_REQUEST = 'GET_SELECTED_REWORK_SERVICES_REQUEST';
export const GET_SELECTED_REWORK_SERVICES_SUCCESS = 'GET_SELECTED_REWORK_SERVICES_SUCCESS';
export const GET_SELECTED_REWORK_SERVICES_FAILURE = 'GET_SELECTED_REWORK_SERVICES_FAILURE';
export const GET_SELECTED_SERVICES_REQUEST = 'GET_SELECTED_SERVICES_REQUEST';
export const GET_SELECTED_SERVICES_SUCCESS = 'GET_SELECTED_SERVICES_SUCCESS';
export const GET_SELECTED_SERVICES_FAILURE = 'GET_SELECTED_SERVICES_FAILURE';
export const UPDATE_SELECTED_SERVICES_REQUEST = 'UPDATE_SELECTED_SERVICES_REQUEST';
export const UPDATE_SELECTED_SERVICES_SUCCESS = 'UPDATE_SELECTED_SERVICES_SUCCESS';
export const UPDATE_SELECTED_SERVICES_FAILURE = 'UPDATE_SELECTED_SERVICES_FAILURE';
export const UPDATE_REWORK_SERVICES_REQUEST = 'UPDATE_REWORK_SERVICES_REQUEST';
export const UPDATE_REWORK_SERVICES_SUCCESS = 'UPDATE_REWORK_SERVICES_SUCCESS';
export const UPDATE_REWORK_SERVICES_FAILURE = 'UPDATE_REWORK_SERVICES_FAILURE';
export const PERMIT_DIRECT_SUBMIT_REQUEST = 'PERMIT_DIRECT_SUBMIT_REQUEST';
export const PERMIT_DIRECT_SUBMIT_SUCCESS = 'PERMIT_DIRECT_SUBMIT_SUCCESS';
export const PERMIT_DIRECT_SUBMIT_FAILURE = 'PERMIT_DIRECT_SUBMIT_FAILURE';
export const GET_PERMIT_BY_ID_REQUEST = 'GET_PERMIT_BY_ID_REQUEST';
export const GET_PERMIT_BY_ID_SUCCESS = 'GET_PERMIT_BY_ID_SUCCESS';
export const GET_PERMIT_BY_ID_FAILURE = 'GET_PERMIT_BY_ID_FAILURE';
export const SET_PAYMET_STATUS_REQUEST = 'SET_PAYMET_STATUS_REQUEST';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const GET_S3_URL_REQUEST = 'GET_S3_URL_REQUEST';
export const GET_S3_URL_SUCCESS = 'GET_S3_URL_SUCCESS';
export const GET_S3_URL_FAILURE = 'GET_S3_URL_FAILURE';
export const PUT_S3_URL_REQUEST = 'PUT_S3_URL_REQUEST';
export const PROMOCODE_REQUEST = 'PROMOCODE_REQUEST';
export const PROMOCODE_SUCCESS = 'PROMOCODE_SUCCESS';
export const PROMOCODE_FAILURE = 'PROMOCODE_FAILURE';
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';
export const SET_MESSAGE_REQUEST = 'SET_MESSAGE_REQUEST';
export const SET_MESSAGE_SUCCESS = 'SET_MESSAGE_SUCCESS';
export const SET_MESSAGE_FAILURE = 'SET_MESSAGE_FAILURE';
export const SET_CHAT_MESSAGE_SUCCESS = 'SET_CHAT_MESSAGE_SUCCESS';
export const GET_APPLICATION_REQUEST = 'GET_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILURE = 'GET_APPLICATION_FAILURE';
export const PUT_APPLICATION_REQUEST = 'PUT_APPLICATION_REQUEST';
export const PUT_APPLICATION_SUCCESS = 'PUT_APPLICATION_SUCCESS';
export const PUT_APPLICATION_FAILURE = 'PUT_APPLICATION_FAILURE';

export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const SHOW_HIDE_LOADER = 'SHOW_HIDE_LOADER';
export const SET_PHOTOS = 'SET_PHOTOS';

export const GET_RACKING_MFS_REQUEST = 'GET_RACKING_MFS_REQUEST';
export const GET_RACKING_MFS_SUCCESS = 'GET_RACKING_MFS_SUCCESS';
export const GET_RACKING_MFS_FAILURE = 'GET_RACKING_MFS_FAILURE';

export const GET_AB_FEATURES_REQUEST = 'GET_AB_FEATURES_REQUEST';
export const GET_AB_FEATURES_SUCCESS = 'GET_AB_FEATURES_SUCCESS';
export const GET_AB_FEATURES_FAILURE = 'GET_AB_FEATURES_FAILURE';

export const GET_UTILITY_PROVIDERS_REQUEST = 'GET_UTILITY_PROVIDERS_REQUEST';
export const GET_UTILITY_PROVIDERS_SUCCESS = 'GET_UTILITY_PROVIDERS_SUCCESS';
export const GET_UTILITY_PROVIDERS_FAILURE = 'GET_UTILITY_PROVIDERS_FAILURE';
export const ACCOUNT_DETAIL_REQUEST = 'ACCOUNT_DETAIL_REQUEST';
export const ACCOUNT_DETAIL_SUCCESS = 'ACCOUNT_DETAIL_SUCCESS';
export const GET_COMPANY_INFO_REQUEST = 'GET_COMPANY_INFO_REQUEST';
export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export const SET_CLOSE_SAVE = 'SET_CLOSE_SAVE';
export const SET_ERROR = 'SET_ERROR';
export const SET_PAYMENT_LOADER = 'SET_PAYMENT_LOADER';

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const PAYMENT_UPGRADE_TO_STORAGE = 'PAYMENT_UPGRADE_TO_STORAGE';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

export const GET_TEMPLATE_REQUEST = 'GET_TEMPLATE_REQUEST';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAILURE = 'GET_TEMPLATE_FAILURE';

export const POST_TEMPLATE_REQUEST = 'POST_TEMPLATE_REQUEST';
export const POST_TEMPLATE_SUCCESS = 'POST_TEMPLATE_SUCCESS';
export const POST_TEMPLATE_FAILURE = 'POST_TEMPLATE_FAILURE';

export const DELETE_TEMPLATE_REQUEST = 'DELETE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const PUT_TEMPLATE_REQUEST = 'PUT_TEMPLATE_REQUEST';
export const PUT_TEMPLATE_SUCCESS = 'PUT_TEMPLATE_SUCCESS';
export const PUT_TEMPLATE_FAILURE = 'PUT_TEMPLATE_FAILURE';

export const PUT_TEMPLATE_TOGGLE_REQUEST = 'PUT_TEMPLATE_TOGGLE_REQUEST';
export const PUT_TEMPLATE_TOGGLE_SUCCESS = 'PUT_TEMPLATE_TOGGLE_SUCCESS';
export const PUT_TEMPLATE_TOGGLE_FAILURE = 'PUT_TEMPLATE_TOGGLE_FAILURE';

export const UPDATE_FEEDBACK_REQUEST = 'UPDATE_FEEDBACK_REQUEST';
export const UPDATE_FEEDBACK_SUCCESS = 'UPDATE_FEEDBACK_SUCCESS';
export const UPDATE_FEEDBACK_FAILURE = 'UPDATE_FEEDBACK_FAILURE';

export const GET_FEEDBACK_REQUEST = 'GET_FEEDBACK_REQUEST';
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS';
export const GET_FEEDBACK_FAILURE = 'GET_FEEDBACK_FAILURE';
export const GET_MOST_USED_VALUES_REQUEST = 'GET_MOST_USED_VALUES_REQUEST';
export const GET_MOST_USED_VALUES_SUCCESS = 'GET_MOST_USED_VALUES_SUCCESS';
export const GET_MOST_USED_VALUES_FAILURE = 'GET_MOST_USED_VALUES_FAILURE';

export const SET_APPLY_TEMPLATE = 'SET_APPLY_TEMPLATE';
export const SET_UPDATED_FROM_TEMPLATE = 'SET_UPDATED_FROM_TEMPLATE';
export const SET_VIEW_TEMPLATE = 'SET_VIEW_TEMPLATE';

export const ADD_EMPTY_TEMPLATE = 'ADD_EMPTY_TEMPLATE';
export const REMOVE_EMPTY_TEMPLATE = 'REMOVE_EMPTY_TEMPLATE';

export const CREATE_EMAIL_NPS_FEEDBACK_REQUEST = 'CREATE_EMAIL_NPS_FEEDBACK_REQUEST';
export const CREATE_EMAIL_NPS_FEEDBACK_SUCCESS = 'CREATE_EMAIL_NPS_FEEDBACK_SUCCESS';
export const CREATE_EMAIL_NPS_FEEDBACK_FAILURE = 'CREATE_EMAIL_NPS_FEEDBACK_FAILURE';

export const SEND_STAMPING_REQUEST = 'SEND_STAMPING_REQUEST';
export const SEND_STAMPING_SUCCESS = 'SEND_STAMPING_SUCCESS';
export const SEND_STAMPING_FAILURE = 'SEND_STAMPING_FAILURE';

export const GET_ARRAY_BUILDER_DETAILS_REQUEST = 'GET_ARRAY_BUILDER_DETAILS_REQUEST';
export const GET_ARRAY_BUILDER_DETAILS_SUCCESS = 'GET_ARRAY_BUILDER_DETAILS_SUCCESS';
export const GET_ARRAY_BUILDER_DETAILS_FAILURE = 'GET_ARRAY_BUILDER_DETAILS_FAILURE';

export const SET_V2_SOLAR_UI = 'SET_V2_SOLAR_UI';

export const SET_IS_FROM_PACKAGE = 'SET_IS_FROM_PACKAGE';

export const ELECTRIAL_REVIEW = 'Electrical Review';
export const STRUCTURAL_REVIEW = 'Structural Review';
export const PHYSICAL_STAMPS = 'Physical Stamps';

export const GET_CART_URL_REQUEST = 'GET_CART_URL_REQUEST';
export const GET_CART_URL_SUCCESS = 'GET_CART_URL_SUCCESS';
export const GET_CART_URL_FAILURE = 'GET_CART_URL_FAILURE';

export const GET_SG_MATERIALS_REQUEST = 'GET_SG_MATERIALS_REQUEST';
export const GET_SG_MATERIALS_SUCCESS = 'GET_SG_MATERIALS_SUCCESS';
export const GET_SG_MATERIALS_FAILURE = 'GET_SG_MATERIALS_FAILURE';

export const ADDITIONAL_STORAGE_REQUEST = 'ADDITIONAL_STORAGE_REQUEST';
export const ADDITIONAL_STORAGE_SUCCESS = 'ADDITIONAL_STORAGE_SUCCESS';
export const ADDITIONAL_STORAGE_FAILURE = 'ADDITIONAL_STORAGE_FAILURE';

export const MORE_INFO_SUBMIT_REQUEST = 'MORE_INFO_SUBMIT_REQUEST';
export const MORE_INFO_SUBMIT_SUCCESS = 'MORE_INFO_SUBMIT_SUCCESS';
export const MORE_INFO_SUBMIT_FAILURE = 'MORE_INFO_SUBMIT_FAILURE';

export const DRAFT_APPLICATION_REQUEST = 'DRAFT_APPLICATION_REQUEST';
export const DRAFT_APPLICATION_SUCCESS = 'DRAFT_APPLICATION_SUCCESS';
export const DRAFT_APPLICATION_FAILURE = 'DRAFT_APPLICATION_FAILURE';
export const REWORK_REQUEST = 'REWORK_REQUEST';
export const REWORK_REQUEST_SUCCESS = 'REWORK_REQUEST_SUCCESS';
export const REWORK_REQUEST_FAILURE = 'REWORK_REQUEST_FAILURE';

export const FILES_DOWNLOADED_REQUEST = 'FILES_DOWNLOADED_REQUEST';
export const FILES_DOWNLOADED_SUCCESS = 'FILES_DOWNLOADED_SUCCESS';
export const FILES_DOWNLOADED_FAILURE = 'FILES_DOWNLOADED_FAILURE';

export const GET_AHJ_RECOMMENDATIONS_REQUEST = 'GET_AHJ_RECOMMENDATIONS_REQUEST';
export const GET_AHJ_RECOMMENDATIONS_SUCCESS = 'GET_AHJ_RECOMMENDATIONS_SUCCESS';
export const GET_AHJ_RECOMMENDATIONS_FAILURE = 'GET_AHJ_RECOMMENDATIONS_FAILURE';

export const SET_CHAT_AUTOMATED_MESSAGES_REQUEST = 'SET_CHAT_AUTOMATED_MESSAGES_REQUEST';

export const IS_URL_VALID_REQUEST = 'IS_URL_VALID_REQUEST';

export const ENABLE_SUBMIT = 'ENABLE_SUBMIT';
export const UPDATE_COORDINATES = 'UPDATE_COORDINATES';

export const GET_AHJID_BYNAME_REQUEST = 'GET_AHJID_BYNAME_REQUEST';
export const GET_AHJID_BYNAME_SUCCESS = 'GET_AHJID_BYNAME_SUCCESS';
export const GET_AHJID_BYNAME_FAILURE = 'GET_AHJID_BYNAME_FAILURE';
export const GET_UTILITYID_BYNAME_REQUEST = 'GET_UTILITYID_BYNAME_REQUEST';
export const GET_UTILITYID_BYNAME_SUCCESS = 'GET_UTILITYID_BYNAME_SUCCESS';
export const GET_UTILITYID_BYNAME_FAILURE = 'GET_UTILITYID_BYNAME_FAILURE';

// Only two types of flag is present in Enlighten
export const FEATURE_FLAG_TYPE = {
  company: 'company_id',
  email: 'email',
};

// Feature flags used in permit
export const FEATURE_FLAGS = {
  DIRECT_SUBMIT: 'PERMIT_DIRECT_SUBMIT',
  OPTIONAL_PHOTO_UPLOAD: 'PERMIT_OPTIONAL_PHOTO_UPLOAD',
  INPUT_FORM_DROPDOWNS: 'PERMIT_INPUT_FORM_DROPDOWNS',
  PERMIT_HIDE_DOWNLOAD_CSV_PDF: 'PERMIT_HIDE_DOWNLOAD_CSV_PDF',
};

export const REQUEST_LISTING_COLUMNS = [
  { id: 'permit_type', minWidth: 100, key: 'permitType' },
  { id: 'permit_id', label: 'REQUEST ID', minWidth: 100, sortable: true, key: 'permitId', hasSearch: true },
  {
    id: 'installer_user_name',
    label: 'REQUESTOR NAME',
    minWidth: 170,
    sortable: true,
    key: 'installerUserName',
    hasSearch: true,
  },
  {
    id: 'project_details.customer_name',
    label: 'HOME OWNER NAME',
    minWidth: 170,
    sortable: true,
    key: 'customerName',
    hasSearch: true,
  },
  { id: 'site_id', label: 'SYSTEM ID', minWidth: 100, sortable: true, key: 'siteId', hasSearch: true },
  { id: 'site_details.name', label: 'SYSTEM NAME', minWidth: 170, sortable: true, key: 'systemName', hasSearch: true },
  { id: 'site_details.zip', label: 'ZIP', minWidth: 80, sortable: true, key: 'zip', hasSearch: true },
  {
    id: 'installer_current_status.state_name',
    label: 'PERMIT ASSISTANCE STATUS',
    minWidth: 170,
    sortable: true,
    key: 'applicationId',
    hasSearch: true,
  },
  {
    id: 'application_date',
    label: 'CREATED ON',
    minWidth: 100,
    sortable: true,
    key: 'applicationDate',
    hasSearch: true,
  },
  {
    id: 'last_modified_date',
    label: 'UPDATED ON',
    minWidth: 100,
    sortable: true,
    key: 'lastUpdatedDate',
    hasSearch: true,
  },
];

export const electicalReviewList = [
  'Utility Meter Location (Zoomed out View)',
  'Main Service Panel Location',
  'Close-up of Main Service Panel Label',
  'Close-up of Main Breaker',
  'Close-up of Main Breaker Label',
  'Sub-Panel Main Breaker (If used)',
  'Sub-Panel Location (If used)',
  'Subpanel Location (If used)',
  'Close-up of Sub-Panel Breaker Label',
  'Proposed Inverter Location (Zoomed out View)',
];

export const structuralReviewList = [
  'Array Location(s)',
  'Entire Roof with Obstructions',
  'Ground Mount Location (If applicable)',
  'Rafter/Truss Size and Spacing (Show tape measure in photo if possible)',
  'Attic Space - Show existing roof rafter/truss for each',
];

export const installDateList = [
  {
    key: 'today',
    text: 'Today',
  },
  {
    key: 'past_7_days',
    text: 'Past 7 Days',
  },
  {
    key: 'month_date',
    text: 'Month to Date',
  },
  {
    key: 'year_date',
    text: 'Year to Date',
  },
  {
    key: 'specific_date',
    text: 'Specific Date',
  },
  {
    key: 'all_dates_before',
    text: 'All Dates Before',
  },
  {
    key: 'all_dates_after',
    text: 'All Dates After',
  },
  {
    key: 'date_range',
    text: 'Date Range',
  },
  {
    key: 'clear_dates',
    text: 'Clear Dates',
  },
];

export const PV_TO_ESS_LINK = `https://enphase.com/installers/microinverters`;

export const STORE_PAYMENT_ERROR_MESSAGE = `Oops! We were not able to complete your transaction.
Please try again or contact`;

export const STORE_REDIRECTION_MESSAGE =
  'You are being redirected to store.enphase.com to complete the checkout process.';

export const PAYMENT_METHOD = 'store';

export const PROJECT_TYPES = {
  COMMERCIAL: 'COMMERCIAL',
  RESIDENTIAL: 'RESIDENTIAL',
};
export const INVALID_LINK_WARNING = 'Please ensure link is correct and accessible';

export const STEPS = [
  {
    name: 'Project',
    description: 'Enter Project details and information about your local Authority having Jurisdiction (AHJ)',
  },
  {
    name: 'System Design',
    description: 'Provide details of the panels and equipment that are already installed/to be installed at the site',
  },
  {
    name: 'Electrical',
    description: 'Provide details of the equipment location and electrical properties at the site',
  },
  {
    name: 'Other Details',
    description: 'Provide site images and related documents that will be helpful in creating the permit package​',
  },
  {
    name: 'Services',
    description: 'Select additional services that you require along with your Permit Drawings',
  },
];

export const INVERTER_TYPES = [
  {
    name: 'Central Inverter',
    key: 'central',
  },
  {
    name: 'Micro Inverter',
    key: 'micro',
  },
  {
    name: 'Hybrid Inverter',
    key: 'hybrid',
  },
];

export const OTHER_MATERIAL_TYPES = [
  {
    id: 'optimizer',
    name: 'Optimizers',
  },
  {
    id: 'acCombiner',
    name: 'AC combiners',
  },
  {
    id: 'acDisconnect',
    name: 'AC Disconnects',
  },
  {
    id: 'dcDisconnect',
    name: 'DC Disconnects',
  },
  {
    id: 'fastener',
    name: 'Standoffs',
  },
  {
    id: 'flashing',
    name: 'Flashings',
  },
  {
    id: 'junctionBox',
    name: 'Junction Boxes',
  },
  // {
  //   id: 'mainServicePanel',
  //   name: 'Main Service Panels'
  // },
  {
    id: 'revenueGradeMeter',
    name: 'Revenue Grade Meters',
  },
];

export const SOLAR_INSTALLATION_TYPES = {
  1: 'New Installation',
  2: 'Energy Storage System Only',
  3: 'Addition of New Panels to Existing Installation',
};

export const DEFAULT_SYSTEM_TYPES = [
  { name: 'Solar', value: false },
  { name: 'Storage', value: false },
];

export const REQUIRED_ELECTRICAL_PROPERTIES = ['service_type', 'utility_meter_location', 'feeder_type', 'msp_location'];

export const ARRAY_FIELDS = ['arrays', 'existing_arrays', 'inverters', 'storage_list', 'other_materials'];

export const ACCEPTABLE_FILE_FORMAT =
  'image/avif,image/bmp,image/gif,image/heic,image/heif,image/jpeg,image/pjpeg,image/png,image/tiff,image/webp,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const OTHER_SITE_LINK_ERROR =
  'You have selected a system with type "Other". Please select a residential or commercial system from the existing list for Permit Assistance';

export const SITE_TYPES = [
  {
    type: 1,
    name: 'Residential',
  },
  {
    type: 2,
    name: 'Commercial',
  },
  {
    type: 3,
    name: 'Other',
  },
];

export const SITE_LINK_ERROR = (siteType, projectType) => {
  const sType = SITE_TYPES.find((s) => s.type === siteType);
  return `You have selected a system with type "${
    sType.name
  }". Please select a ${projectType.toLowerCase()} system from the existing list`;
};

export const ENPHASE_MANUFACTURER_SG_ID = '1964';

export const ATTIC_PHOTO_TYPE = 'Attic Space - Show existing roof rafter/truss for each';

export const REQUIRED_SERVICES = {
  PERMIT_DRAWINGS: 'Permit Drawings',
  ELECTRICAL_REVIEW: 'Electrical Review',
  STRUCTURAL_REVIEW: 'Structural Review',
  PHYSICAL_STAMPS: 'Physical Stamps',
  CARPORT_INSTALLATION: 'Carport Installation',
};

export const SUBPANEL_FIELDS = {
  bus_rating: 'bus_rating',
  breaker_rating: 'breaker_rating',
  service_type: 'service_type',
  breaker_location: 'breaker_location',
  spare_breaker_count: 'spare_breaker_count',
};

export const RE_STAMPING_SERVICES = {
  RE_ELECTRICAL_REVIEW: 'Re Stamping Electrical Review',
  RE_STRUCTURAL_REVIEW: 'Re Stamping Structural Review',
};

export const SUBPANEL_TAGS = {
  existing_open_subpanel: 'other-section-Existing Open Sub Panel',
  subpanel_sticker: 'other-section-Sub Panel Sticker',
};

export const ELECTRICAL_TAGS = {
  msp_type: 'other-section-Main Service Panel Location',
  utility_meter: 'other-section-Utility Meter Location',
  subpanel_breaker_rating: 'other-section-Sub Panel Location',
  service_panel_sticker: 'other-section-Service Panel Sticker',
  service_panel_without_dead_front: 'other-section-Service Panel Wihout Dead Front',
  ...SUBPANEL_TAGS,
};

export const REWORK_REQUEST_REASONS = ['Homeowner', 'Installer', 'AHJ', 'Utility', 'Homeowner Association (HOA)'];

export const AHJ_RECOMMENDATIONS_DISCLAIMER = `The jurisdiction selected may not be an applicable AHJ for permit submission.
  If requirements cannot be located, we will utilize the next applicable tier of governing rules (City -> County -> State)`;

export const MAXIMUM_UPLOAD_FILE_SIZE = '20MB';

export const UPDATE_COUNTY = 'UPDATE COUNTY';

export const ORDER_STAMPS = 'ORDER STAMPS';

export const CARPORT_INSTALLATION = 'Carport Installation';

export const STAMPING_INFO_TEXT = 'Enphase will perform the stamping on latest permit package on successful purchase';

export const HELP_GUIDE_LINK = 'https://enphase.com/download/permit-assistance-help-guide';

export const CONTACT_EMAIL = 'permitassistance@enphaseenergy.com';

export const MSP_UM_MARKER = {
  FIELD_TEXT: 'Place the marker on exact MSP and Utility Meter location',
  INFO_TEXT: 'Please click on the marker and drag it to the desired location',
};

export const fileTypes = {
  json: ['application/json'],
  doc: ['application/msword'],
  pdf: ['application/pdf'],
  rar: ['application/x-rar-compressed'],
  xml: ['application/xml', 'text/xml'],
  zip: ['application/zip', 'application/x-zip-compressed', 'application/octet-stream', 'multipart/x-zip'],
  xls: ['application/vnd.ms-excel'],
  ppt: ['application/vnd.ms-powerpoint'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  pptx: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  xlsm: ['application/vnd.ms-excel.sheet.macroenabled.12'],
  txt: ['application/plain', 'text/plain'],
  avif: ['image/avif'],
  bmp: ['image/bmp'],
  gif: ['image/gif'],
  heic: ['image/heic'],
  heif: ['image/heif'],
  jfif: ['image/jpeg'],
  jpeg: ['image/jpeg'],
  jpg: ['image/jpeg'],
  pjpeg: ['image/pjpeg'],
  png: ['image/png'],
  psd: ['image/vnd.adobe.photoshop'],
  tif: ['image/tiff'],
  tiff: ['image/tiff'],
  webp: ['image/webp'],
  dwg: ['image/vnd.dwg', 'image/x-dwg', 'application/dwg', 'application/acad', 'application/x-acad'],
  dxf: ['image/vnd.dxf', 'image/x-dxf', 'application/dxf'],
  dng: ['image/x-adobe-dng'],
  csv: ['text/csv'],
  log: ['text/plain'],
  stl: ['model/stl'],
};
export const fileUtils = {
  defaultType: Object.keys(fileTypes)
    .map((item) => `.${item}`)
    .join(','),
  mediaType: '.avif, .bmp, .gif, .heic, .heif, .jfif, .jpeg, .jpg, .pjpeg, .png, .tif, .tiff, .webp',
  documentType: '.doc, .docx, .pdf',
  csvType: '.xls, .xlsx, .xlsm, .csv',
};
export const ACCEPTABLE_SPEC_SHEET_FILE_FORMAT =
  fileUtils.mediaType + ',' + fileUtils.documentType + ',' + fileUtils.csvType;
