import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import HelpTextIcon from '../../../../common/HelpTextIcon';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  dataSourceField: {
    marginTop: theme.spacing(6)
  }
}));

const RequiredPlanSetNotesSection = props => {
  const classes = useStyles();
  const { readOnly, stateSelected, sectionLabel, requiredPlanNotes, handleRequiredPlanNotes } = props;
  const { cover_sheet = '', site_and_roof_plan = '', electrical = '', placards = '' } = requiredPlanNotes;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel
        header={sectionLabel}
        icon={
          <HelpTextIcon
            message="Notes added here will be appended in the final permit package"
            position="right"
            btnClassName={classes.button}
          />
        }
      >
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
          <Box id="cover_sheet" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (cover sheet)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={cover_sheet}
                onChange={e => handleRequiredPlanNotes(e, 'cover_sheet')}
              />
            </Box>
            <Box id="site_and_roof_plan" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (site & roof plan)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={site_and_roof_plan}
                onChange={e => handleRequiredPlanNotes(e, 'site_and_roof_plan')}
              />
            </Box>
            <Box id="electrical" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (electrical)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={electrical}
                onChange={e => handleRequiredPlanNotes(e, 'electrical')}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
          <Box id="placards" className={classes.addressWrapper}>
              <TextField
                className={clsx(classes.inputField, classes.dataSourceField)}
                label="Required notes (placards)"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                disabled={!stateSelected || readOnly}
                value={placards}
                onChange={e => handleRequiredPlanNotes(e, 'placards')}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default RequiredPlanSetNotesSection;
