export const YES_NO_OPTIONS = [
  { title: 'Yes', value: Boolean(true) },
  { title: 'No', value: Boolean(false) },
];

export const SURGE_PROTECTION_REQUIRED_OPTIONS = [
  { title: 'Yes, when designed according to NEC 2020 or above', value: 'yes_when_designed_according_to_NEC_2020_or_above' },
  { title: 'No', value: 'no' },
];

export const PV_METER_LOCATION_OPTIONS = [
  { title: 'No', value: 'no' },
  { title: 'Load side', value: 'load_side' },
  { title: 'Line side', value: 'line_side' },
  { title: 'Between ACD and MSP', value: 'acd_msp' },
  { title: 'Between ACD and inverter/combiner', value: 'acd_inverter' },
];

export const PV_METER_RRQUIREMENT_OPTIONS = [
  { title: 'Yes, when total system size is greater than or equal to 10kW DC', value: 'yes_when_total_system_size_greater_than_or_equal_to_10kW_DC' },
  { title: 'Yes, when total system size is greater than 10kW DC', value: 'yes_when_total_system_size_greater_than_10kW_DC' },
  { title: 'Yes, when total system size is greater than 10kW AC', value: 'yes_when_total_system_size_greater_than_10kW_AC' },
  { title: 'Yes, when total system size is greater than or equal to 30kW AC ', value: 'yes_when_total_system_size_greater_than_or_equal_to_30kW_AC' },
  { title: 'Yes, when total system size is greater than 40kW AC', value: 'yes_when_total_system_size_greater_than_40kW_AC' },
  { title: 'Yes, when total system size CEC AC rating is greater than or equal to 15kW AC', value: 'yes_when_total_system_size_CEC_AC_rating_greater_than_or_equal_to_15kW_AC' },
  { title: 'Yes, when the customer chooses smart program', value: 'yes_when_the_customer_chooses_smart_program' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const AC_DISCONNECT_REQUIRED_OPTIONS_ELECTRICAL = [
  { title: 'Yes, when total system size is greater than or equal to 30kW AC', value: 'yes_when_total_system_size_greater_than_or_equal_to_30kW_AC' },
  { title: 'Yes, when MSP rating is greater than 320A(Continuous Current)', value: 'yes_when_MSP_rating_greater_than_320A' },
  { title: 'Yes, when MSP rating is greater than or equal to 400A', value: 'yes_when_MSP_rating_greater_than_or_equal_to_400A' },
  { title: 'Yes, when the inverter/combiner box is located more than 6ft from MSP or not in the line of sight', value: 'yes_when_inverter_or_combiner_box_located_more_than_6ft_from_MSP_or_not_in_line_of_sight' },
  { title: 'Yes, when the inverter/combiner box is located more than 10ft from MSP or not in the line of sight', value: 'yes_when_inverter_or_combiner_box_located_more_than_10ft_from_MSP_or_not_in_line_of_sight' },
  { title: 'Yes, when generator is used', value: 'yes_when_generator_is_used' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const AC_DISCONNECT_REQUIRED_OPTIONS_SYSTEM_EXPANSION = [
  { title: 'Yes, after solar load center', value: 'yes_after_solar_load_center' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export const CONDUIT_ALLOWED_OPTIONS = [
  { title: 'Rigid metal conduit (RMC)', value: 'rigid_metal_conduit' },
  { title: 'Galvanized rigid conduit(GRC)', value: 'galvanized_rigid_conduit' },
  { title: 'Intermediate metal conduit (IMC)', value: 'intermediate_metal_conduit' },
  { title: 'Electrical metallic tubing (EMT)', value: 'electrical_metallic_tubing' },
  { title: 'Rigid non-metallic conduit (RNC)', value: 'rigid_non_metallic_conduit' },
  { title: 'Reinforced thermosetting resin conduit (RTRC)', value: 'reinforced_thermosetting_resin_conduit' },
  { title: 'Polyvinyl chloride (PVC)', value: 'polyvinyl_chloride' },
  { title: 'Romex', value: 'romex' },
  { title: 'All', value: 'all' },
];

export const METER_SOCKET_ADAPTOR_PROGRAM_OPTIONS = [
  { title: 'Renewable meter adaptor (RMA)', value: 'renewable_meter_adaptor' },
  { title: 'Green meter adaptor (GMA)', value: 'green_meter_adaptor' },
  { title: 'Generation meter adaptor (GMA)', value: 'generation_meter_adaptor' },
  { title: 'Generation meter adaptor (GMA), when PV only system is installed', value: 'generation_meter_adaptor_when_PV_only_system_installed' },
  { title: 'No', value: 'no' },
];